import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Auth_Service } from 'app/core/auth/auth.service';
import { FormArray } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { User_Service } from 'app/core/user/user.service';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { api_response } from './shared.types';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class Shared_Service {
    constructor(
        private _as: Auth_Service,
        private _us: User_Service,
        private _snackBar: MatSnackBar,
        private _sanitizer: DomSanitizer
    ) { }

    private _page: BehaviorSubject<string> = new BehaviorSubject(null);
    private _search_key: BehaviorSubject<string> = new BehaviorSubject(null);

    get page$(): Observable<string> {
        return this._page.asObservable();
    }
    get search_key$(): Observable<string> {
        return this._search_key.asObservable();
    }



    sanitize(html: string): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(html);
    }
    object_element(object: any, key: string) {
        let keys = key.split(".");
        let value = object;
        keys.forEach(k => {
            value = value[k];
        });
        return value;
    }

    sKey(key: string): Observable<string> {
        if (key.length != 0) {
            this._search_key.next(key);
        }
        return this.search_key$;
    }

    tText(key: string, type: string = '') {
        if (this._as.is_admin) {
            if (type != "") {
                type = "/" + type;
            }
            return environment.api_url + "manager/translate/" + key + type;
        }
        return "#";
    }

    tCKey(key: string, prefix: string = '') {
        prefix = this.slugify(prefix);
        key = this.slugify(key).replace(prefix + "_", "");
        return [prefix, key].join("_");
    }

    tClass() {
        return this._as.is_admin;
    }

    tLog(x: any) {
        console.log(x);
    }

    slugify(text: string, join: string = "_") {
        return text.toLowerCase().split(" ").join(join).split("-").join(join).split("&").join(join);
    }

    unslugify(text: string, join: string = "_") {
        return text.split(join).join(" ").split("-").join(" ").replace(/(\w)(\w*)/g,
            function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
    }

    build_path(...paths: any[]) {
        return paths.join("/");
    }

    range(end, start = 0, step = 1) {
        return (Array.from({ length: end + 1 - start }, (_, i) => i + step - 1 + start));
    }

    FormArray(fa: any) {
        return fa as FormArray;
    }

    Abs(number: number): number {
        return Math.abs(number);
    }
    Max(number1: number, number2: number): number {
        return Math.max(number1, number2);
    }

    snack(message: string, action: string, config: MatSnackBarConfig = null) {
        config = config ?? new MatSnackBarConfig();
        config.duration = config.duration ?? 5000;
        config.horizontalPosition = "right";
        config.verticalPosition = "top";
        this._snackBar.open(message, action, config);
    }

    page(key: string): Observable<string> {
        if (key.length != 0) {
            this._page.next(key);
        }
        return this.page$;
    }

    default(v: string, d: string) {
        return (v == null || v.trim() == "") ? d : v;
    }

    object_keys(object: object): Array<string> {
        return Object.keys(object);
    }

    //TODO::MOVE TO SIGNATURE
    private _signature: BehaviorSubject<object> = new BehaviorSubject(null);
    get signature$(): Observable<object> {
        return this._signature.asObservable();
    }
    signature(signature: object): Observable<object> {
        this._signature.next(signature);
        return this.signature$;
    }
}