import { Component, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Shared_Service } from 'app/shared/shared.service';

@Component({
  selector: 'ashcorp-field-seperator',
  templateUrl: './ashcorp-field-seperator.component.html',
  animations: fuseAnimations
})

export class Ashcorp_Field_Seperator_Component {
  @Input() class?: any;
  @Input() style?: any = "height: 5px;margin-top: -50px;";

  public helper: Shared_Service;
  public break_line: number[] = [];

  constructor(private _shared_service: Shared_Service) {
    this.helper = this._shared_service;
  }
}
