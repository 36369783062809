import { Component, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Shared_Service } from 'app/shared/shared.service';

@Component({
  selector: 'ashcorp-button',
  templateUrl: './ashcorp-button.component.html',
  animations: fuseAnimations
})

export class Ashcorp_Button_Component {
  @Input() component: any;
  @Input() color: string;
  @Input() click: any;

  public helper: Shared_Service;

  constructor(private _shared_service: Shared_Service) {
    this.helper = this._shared_service;
  }

  public event(action: string) {
    this.component[action]();
  }
}
