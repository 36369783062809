<div [formGroup]="form" *ngIf="selections">
    <mat-form-field style="width:100%;">
        <mat-label *ngIf="show">{{label}}</mat-label>
        <mat-select [formControlName]="name">
            <mat-option *ngFor="let option of selections;" [value]="option.value">{{option.label}}</mat-option>
        </mat-select>
        <mat-error *ngIf="show && form && form.get(name).hasError('required')">
            {{label}} est <strong>requise</strong>
        </mat-error>
    </mat-form-field>
</div>