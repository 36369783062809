<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<div class="w-full bg-white print:hidden">
    <header class="section page-header">
        <div class="rd-navbar-wrap" style="height: 102px;">
            <nav class="rd-navbar rd-navbar-minimal rd-navbar-original rd-navbar-static" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fixed" data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-static" data-lg-device-layout="rd-navbar-fixed" data-xl-layout="rd-navbar-static" data-xl-device-layout="rd-navbar-static" data-xxl-layout="rd-navbar-static" data-xxl-device-layout="rd-navbar-static" data-lg-stick-up-offset="46px" data-xl-stick-up-offset="46px" data-xxl-stick-up-offset="46px" data-lg-stick-up="true" data-xl-stick-up="true" data-xxl-stick-up="true">
                <div class="rd-navbar-main-outer">
                    <div class="rd-navbar-main">
                        <div class="rd-navbar-panel">
                            <button class="rd-navbar-toggle toggle-original" data-rd-navbar-toggle="#rd-navbar-nav-wrap-1">
                                <span></span>
                            </button>
                            <a class="rd-navbar-brand" href="/">
                                <img src="assets/images/logo/logo.png" alt="" style="height:41px;">
                            </a>
                        </div>
                        <div class="rd-navbar-main-element">
                            <div class="rd-navbar-nav-wrap toggle-original-elements" id="rd-navbar-nav-wrap-1">
                                <ul class="rd-navbar-nav">
                                    <li class="rd-nav-item" [class]="{'active': page == 'store'}">
                                        <a class=" rd-nav-link" href="/">Delo Store</a>
                                    </li>
                                    <li class="rd-nav-item" [class]="{'active': page == 'contact'}">
                                        <a class=" rd-nav-link" href="contact">Contact</a>
                                    </li>
                                    <li class="rd-nav-item" [class]="{'active': page == 'connexion'}">
                                        <a class="rd-nav-link" href="/{{is_connected ? 'deconnexion' : 'connexion'}}">{{is_connected ? 'Déconnexion' : 'Connexion'}}</a>
                                    </li>
                                </ul>
                            </div>
                            <!-- <div class="rd-navbar-call">
                                <a class="rd-nav-link" href="/callback" style="color:white;">
                                    <i class="material-icons md-16" style="margin-top: 4px;">call</i>
                                </a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </header>

    <div class="body pt-0">
        <div class="main">
            <router-outlet *ngIf="true"></router-outlet>
            <a href="{{app_url}}#top" class="scroll-to-top" title="Back up">
                <i class="material-icons"></i>
            </a>
        </div>
    </div>

    <footer class="section footer-standard bg-gray-modern">
        <div class="footer-standard-main">
            <div class="container">
                <div class="row row-50">
                    <div class="col-lg-4">
                        <div class="inset-right-1">
                            <h4>Qui sommes nous</h4>
                            <p>
                                Leader du traitement de l’eau dans l’Océan Indien depuis 1991, Domeau a déjà équipé plus de
                                20.000 foyers qui bénéficient d’eau purifiée chez eux.
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-5 col-lg-4">
                        <div class="box-1">
                            <h4>Nos Contacts</h4>
                            <ul class="list-sm list-footer">
                                <li class="object-inline">
                                    <i class="material-icons md-16">place</i>
                                    <a class="link-default" href="#">Healthscape, Forbach</a>
                                </li>
                                <li class="object-inline">
                                    <i class="material-icons md-16">call</i>
                                    <a class="link-default" href="tel:2670000">(+230) 267 0000</a>
                                </li>
                                <li class="object-inline">
                                    <i class="material-icons md-16">email</i>
                                    <a class="link-default" href="mailto:info@delo.mu">info@delo.mu</a>
                                </li>
                                <li style="font-size: 25px;margin: 12px -5px;font-size: 25px;">
                                    <a class="link-default" href="https://fr-fr.facebook.com/pure.water.mauritius/" style="margin-right: 10px;">
                                        <i class="fa fa-facebook fa-fw"></i>
                                    </a>
                                    <a class="link-default" href="https://www.instagram.com/delobydomeau/?hl=fr" style="margin-right: 10px;">
                                        <i class="fa fa-instagram fa-fw"></i>
                                    </a>
                                    <a class="link-default" href="https://mu.linkedin.com/company/delo-by-domeau-mauritius-water-filtration" style="margin-right: 10px;">
                                        <i class="fa fa-linkedin fa-fw"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-7 col-lg-4">
                        <div class="box-1">
                            <h4>Où sommes nous</h4>
                            <ul class="list-sm list-footer">
                                <li class="object-inline">Forbach, Healthscape </li>
                                <li class="object-inline">Ebène, Intermart </li>
                                <li class="object-inline">Flacq, Super U </li>
                                <li class="object-inline">Rosebelle, Plaisance </li>
                                <li class="object-inline">Tamarin, Super U </li>
                                <li class="object-inline">Tribeca</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="footer-standard-aside justify-content-center">
                <p class="rights">
                    <span>&copy;&nbsp;</span>{{current_year}}
                    <span>&nbsp;</span>
                    <span>DOM'EAU (MAURITIUS) LTD - île Maurice. Tous droits réservés | All Rights Reserved</span>
                </p>
            </div>
        </div>
    </footer>
</div>