import { FormGroup } from "@angular/forms";

export class Ashcorp_Field_Component {

  requires_any(form: FormGroup, field: string, errors: Array<string>) {
    let result = false;
    if (!form) return result;
    if (!form.get(field)) return result;
    errors.forEach((error: string) => {
      if (form.get(field).hasError(error)) {
        result = true;
        return result;
      }
    }
    );
    return result;
  }

  is_dirty_or_touched(form: FormGroup, field: string) {
    let result = false;
    if (!form) return result;
    if (!form.get(field)) return result;
    result = form.get(field).dirty || form.get(field).touched;
    return result;
  }
}