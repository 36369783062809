import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Ashcorp_Field_Component } from '../ashcorp-field.component';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'ashcorp-field-signature',
  templateUrl: './ashcorp-field-signature.component.html'
})
export class Ashcorp_Field_Signature_Component extends Ashcorp_Field_Component {
  @Input() form: FormGroup;
  @Input() field: string;

  @ViewChild('canvas', { static: false }) set set_canvas(canvas: ElementRef) {
    if (canvas) {
      this.pad = new SignaturePad(canvas.nativeElement);
    }
  }

  private pad!: SignaturePad;

  constructor() {
    super();
  }

  save() {
    const signature = this.pad.toDataURL();
    this.form.get(this.field).setValue(signature);
    this.form.get(this.field).markAsTouched();
  }

  clear() {
    this.pad.clear();
    this.form.get(this.field).setValue(null);
    this.form.get(this.field).markAsTouched();
    this.form.get(this.field).setErrors({ 'required': true });
  }
}