import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { User_Component } from './user.component';
import { Shared_Module } from 'app/shared/shared.module';

@NgModule({
    declarations: [
        User_Component
    ],
    imports: [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        Shared_Module
    ],
    exports: [
        User_Component
    ]
})
export class User_Module {
}
