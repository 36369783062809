import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { FuseNavigationModule } from '@fuse/components/navigation';

import { User_Module } from 'app/layout/common/user/user.module';
import { Shared_Module } from 'app/shared/shared.module';
import { Manager_Layout_Component } from 'app/layout/layouts/manager/manager.layout.component';

@NgModule({
    declarations: [
        Manager_Layout_Component
    ],
    imports: [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseFullscreenModule,
        FuseLoadingBarModule,
        FuseNavigationModule,
        User_Module,
        Shared_Module
    ],
    exports: [
        Manager_Layout_Component
    ]
})
export class Manager_Layout_Module {
}
