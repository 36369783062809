import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Shared_Service } from "app/shared/shared.service";

import { Navigation_Service } from "app/core/navigation/navigation.service";
import { Ashcorp_Form } from "./ashcorp-form.types";

@Component(
    {
        selector: 'ashcorp-form',
        templateUrl: './ashcorp-form.component.html',
        changeDetection: ChangeDetectionStrategy.Default
    }
)

export class Ashcorp_Form_Component {
    @Input() component: Ashcorp_Form;

    constructor(public _: Navigation_Service, public _shared_service: Shared_Service) { }
}