import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService } from '@fuse/components/navigation';
import { Navigation_Service } from 'app/core/navigation/navigation.service';
import { environment } from 'environments/environment';
import { Shared_Service } from 'app/shared/shared.service';
import { Auth_Service } from 'app/core/auth/auth.service';

@Component({
    selector: 'store-layout',
    templateUrl: './store.component.html',
    styleUrls: ['./store.component.css', './store.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default
})
export class Store_Layout_Component {
    public cdn_url: string = environment.cdn_url;
    public api_url: string = environment.api_url;
    public get app_url(): string { return window.location.href.split("#")[0]; }

    public page: string = "store";
    public get is_connected(): boolean {
        return this._as.is_auth;
    }

    constructor(
        private _shared_service: Shared_Service,
        private _as: Auth_Service,
    ) {
        this._shared_service.page$.subscribe(title => { this.page = title; });
    }

    get current_year(): number {
        return new Date().getFullYear();
    }
}
