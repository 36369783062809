import { NgModule } from '@angular/core';
import { LayoutComponent } from 'app/layout/layout.component';
import { Empty_Layout_Module } from 'app/layout/layouts/empty/empty.module';
import { Store_Layout_Module } from 'app/layout/layouts/store/store.module';
import { Manager_Layout_Module } from 'app/layout/layouts/manager/manager.layout.module';
import { Shared_Module } from 'app/shared/shared.module';

const layoutModules = [
    // Empty
    Empty_Layout_Module,
    Store_Layout_Module,
    Manager_Layout_Module
];

@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports: [
        Shared_Module,
        ...layoutModules
    ],
    exports: [
        LayoutComponent,
        ...layoutModules
    ]
})
export class Layout_Module {
}
