<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="false">
        <ng-container fuseVerticalNavigationContentHeader> </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
         class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <ng-container *ngIf="!isScreenSmall">
            <!-- Logo -->
            <div class="flex items-center mx-2 lg:mr-8">
                <div class="hidden lg:flex">
                    <img class="dark:hidden w-20" [src]="cdn_url + 'images/logo.png'">
                    <img class="hidden dark:flex w-20" [src]="cdn_url + 'images/logo.png'">
                </div>
                <img class="flex lg:hidden w-8" [src]="cdn_url + 'images/logo.png'">
            </div>
            <!-- Horizontal navigation -->
            <fuse-horizontal-navigation class="mr-2" [name]="'mainNavigation'" [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <languages></languages> -->
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <user></user>
        </div>
    </div>

    <!-- Breadcrumbs -->
    <div class="row flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent m-0"
         *ngIf="company!=undefined && breadcrumb!=undefined">
        <div class="col-sm-6 flex-1 min-w-0">
            <div class="flex flex-wrap items-center font-medium">
                <div *ngIf="breadcrumb.company">
                    <a class="capitalize whitespace-nowrap text-primary-500">{{company.title}}</a>
                </div>
                <div *ngIf="breadcrumb.branch">
                    <a class="capitalize whitespace-nowrap text-primary-500">{{branch.title}}</a>
                </div>
                <div class="flex items-center ml-1 whitespace-nowrap" *ngFor="let crumb of breadcrumb.crumbs;">
                    <mat-icon class="icon-size-5 text-secondary" [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
                    <a class="capitalize ml-1 text-primary-500" [href]="crumb.url">{{crumb.name}}</a>
                </div>
            </div>
            <div class="mt-2">
                <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                    {{breadcrumb.title}}
                </h2>
            </div>
        </div>
        <div class="col-sm-4 flex-1 min-w-0 text-right" *ngIf="breadcrumb.search">
            <mat-form-field appearance="standard">
                <input matInput (keyup)="apply_filter($event)" placeholder="Search" #input>
            </mat-form-field>
        </div>
        <div class="col-sm-2 flex-1 min-w-0 text-right" *ngIf="breadcrumb.create">
            <button class="hidden sm:inline-flex ml-3" mat-stroked-button (click)="go_to(breadcrumb.create.url)">
                <mat-icon class="icon-size-6" [svgIcon]="breadcrumb.create.icon"></mat-icon>
                {{breadcrumb.create.name}}
            </button>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
         class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Dom'eau (Mauritius) Ltd &copy; {{currentYear}}</span>
    </div>

</div>