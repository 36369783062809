import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { DashboardDataResolver } from './modules/dashboard/dashboard.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // { path: '', pathMatch: 'full', redirectTo: 'maintenance' },
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Dashboard
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: DashboardDataResolver,
        },
        data: {
            layout: 'manager'
        },
        children: [
            { path: '', loadChildren: () => import('app/modules/dashboard/dashboard.module').then(m => m.Dashboard_Module) }
        ]
    },

    // Store
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'store'
        },
        children: [

            { path: 'contrats-de-maintenance', loadChildren: () => import('app/modules/store/store.module').then(m => m.Store_Module), data: { section: 'contrats-de-maintenance', title: 'Contrats de Maintenance' } },
            { path: 'extensions-de-garantie', loadChildren: () => import('app/modules/store/store.module').then(m => m.Store_Module), data: { section: 'extensions-de-garantie', title: 'Extensions de Garantie' } },
            { path: 'maintenances-sur-demande', loadChildren: () => import('app/modules/store/store.module').then(m => m.Store_Module), data: { section: 'maintenances-sur-demande', title: 'Maintenances sur Demande' } },
            { path: '', loadChildren: () => import('app/modules/store/store.module').then(m => m.Store_Module), data: { section: 'home', title: '' } },
            { path: 'contact', loadChildren: () => import('app/modules/store/store.module').then(m => m.Store_Module), data: { section: 'contact-us', title: '' } },
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'deconnexion', loadChildren: () => import('app/core/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            { path: 'sign-out', loadChildren: () => import('app/core/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            // { path: 'unlock-session', loadChildren: () => import('app/core/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) },
            { path: 'confirmation-required', loadChildren: () => import('app/core/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            // { path: 'reset-password', loadChildren: () => import('app/core/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
        ]
    },

    // System routes
    {
        path: '',
        component: LayoutComponent,
        resolve: {
            // initialData: InitialDataResolver,
        },
        data: {
            layout: 'empty'
        },
        children: [
            // { path: 'comming-soon', pathMatch: 'full', loadChildren: () => import('app/modules/system/coming-soon/coming-soon.module').then(m => m.ComingSoonModule) },
            { path: 'maintenance', pathMatch: 'full', loadChildren: () => import('app/modules/system/maintenance/maintenance.module').then(m => m.MaintenanceModule) },
            { path: 'connexion', loadChildren: () => import('app/core/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'sign-in', loadChildren: () => import('app/core/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'forgot-password', loadChildren: () => import('app/core/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },

            // Error
            {
                path: 'error', children: [
                    { path: '404', loadChildren: () => import('app/modules/system/error/error-404/error-404.module').then(m => m.Error404Module) },
                    { path: '500', loadChildren: () => import('app/modules/system/error/error-500/error-500.module').then(m => m.Error500Module) }
                ]
            },
        ]
    },

    // Catch all
    { path: '**', redirectTo: 'error/404' }
];
