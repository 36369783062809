<div [formGroup]="form">

    <ng-container *ngIf="!form.valid">
        <mat-error class="mat-error" style="font-size:14px;font-weight:500;line-height:1;text-align:center;margin-bottom:14px;" *ngIf="requires_any(form, field, ['required','minlength','requiredPattern']) && (is_dirty_or_touched(form, field))">
            Signature est <strong>requise</strong>
        </mat-error>
        <canvas #canvas style="border:2px solid #286efc;;border-radius: 5px 5px 0 0;margin: auto;" [id]="'canvas-' + field" width="300" height="200"></canvas>
        <button class="button button-primary-outline button-icon button-icon-left button-winona" style="width: 152px;margin-top: -2px;border-top: none;border-right: 1px solid #286efc;border-radius: 0 0 0 5px;" (click)="clear();">
            <i class="material-icons md-16">close</i>
        </button>
        <button class="button button-primary-outline button-icon button-icon-left button-winona" style="width: 152px;margin-top: -2px;border-top: none;border-left: 1px solid #286efc;border-radius: 0 0 5px 0;" (click)="save();">
            <i class="material-icons md-16">done</i>
        </button>
    </ng-container>

    <ng-container *ngIf="form.valid">
        <img style="margin:4px 0;width: 300px;height: 200px;" [src]="form.get('value').value" />
    </ng-container>

</div>